





























import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    DatasetUpload: () => import("@/components/DatasetUpload.vue")
  }
})
export default class CreatePhotoCollection extends Vue {
  // Data
  valid = false;
  uploadError = false;
  uploadErrorText = "";
  fullProgress = false;

  // Methods
  submit() {
    const datasetUpload: any = this.$refs.datasetUpload;
    datasetUpload.createPhotoCollection();
  }

  setUploadError(errorText: string) {
    this.fullProgress = false;
    this.uploadError = true;
    this.uploadErrorText = errorText;
  }

  setFullProgress() {
    this.fullProgress = true;
    this.uploadError = false;
    this.uploadErrorText = "";
  }
}
